<template>
  <div class="form-group">
    <div class="dataSource no-select" v-if="showLabel">
      <template v-if="inputTypeList.length > 1">
        <label style="display:block;" v-if="label">{{ $t(label) }}</label>
        <label
          :for="`${item}${ix}`"
          v-for="(item, ix) in inputTypeList"
          :key="ix"
          class="clicable small-label"
          style="font-weight: normal"
        >
          <input
            type="radio"
            v-model="inputType"
            :id="`${item}${ix}`"
            :value="item"
          />
          <span>{{ itemText(item) }}</span>
        </label>
      </template>
      <template v-if="inputTypeList.length == 1">
        <label class="clicable">
          <span>
            {{ $tc(inputType, 1) }}
            <Tooltip :title="$t(`hints.${inputType}`)" />
          </span>
        </label>
      </template>
      <template v-if="inputTypeList.length == 0">
        <label class="clicable" for="format">
          <span> {{ $tc("format", 1) }} </span>
        </label>
      </template>
    </div>
    <v-select
      ref="vsel"
      inputId="vsel"
      v-if="inputType == 'date_time_format'"
      v-model="iValue"
      :options="datetimeOptions"
      taggable
      push-tags
      class="v-select" 
      @search:focus="onFocus"
    >
      <template v-slot:option="option">
        <div class="select-option">
          <div>{{ option.label }}</div>
          <div class="small text-info">
            <span class="small">{{ datetimeExample(option) }}</span>
          </div>
        </div>
      </template>
    </v-select>
    <v-select
      ref="vsel"
      inputId="vsel"
      v-else-if="inputType == 'duration_format'"
      v-model="iValue"
      :options="durationOptions"
      taggable
      push-tags
      class="v-select"
      @search:focus="onFocus"
    >
      <template v-slot:option="option">
        <div class="select-option">
          <div>{{ option.label }}</div>
          <div class="small text-info">
            <span class="small">{{ durationExample(option) }}</span>
          </div>
        </div>
      </template>
    </v-select>
    <v-select
      ref="vsel"
      inputId="vsel"
      v-else-if="inputType == 'numeric_format'"
      v-model="iValue"
      :options="numericOptions"
      taggable
      push-tags
      class="v-select v-select-number-format"
      @search:focus="onFocus"
    >
      <template v-slot:option="option">
        <div class="select-option">
          <div>{{ option.label }}</div>
          <div class="small text-info">
            <span class="small">{{ numericExample(option) }}</span>
          </div>
        </div>
      </template>
    </v-select>
    <input
      v-else-if="!inputType"
      type="text"
      class="form-control"
      name="format"
      v-model="iValue"
      autocomplete="off"
    />
  </div>
</template>

<script>
import Tooltip from "@/components/tooltip.vue";

export default {
  name: "DataFormatInput",
  components: {
    Tooltip
  },
  props: {
    inputTypeList: {
      type: Array,
      required: false,
      default: () => [
        "date_time_format",
        "numeric_format",
        "text_list",
        "duration_format"
      ]
    },
    defaultInputType: {
      type: String,
      required: false,
      default: "numeric_format"
    },
    value: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: false,
      default: "format"
    },
    showLabel: {
      type: Boolean,
      default: true,
      required: false
    },
    durationUnit: {
      type: String,
      required: false,
      default: "seconds"
    }
  },
  data() {
    return {
      inputType:
        this.inputTypeList.length == 1
          ? this.inputTypeList[0]
          : this.defaultInputType,
      iValue: undefined
    };
  },
  computed: {
    datetimeOptions() {
      let lst = JSON.parse(JSON.stringify(this._datetimeOptions));
      if (this.value) {
        if (!lst.find(({ value }) => value == this.value)) {
          lst.push({ label: this.value, value: this.value });
        }
      }
      return lst;
    },
    durationOptions() {
      let lst = JSON.parse(JSON.stringify(this._durationOptions));
      if (this.value) {
        if (!lst.find(({ value }) => value == this.value)) {
          lst.push({ label: this.value, value: this.value });
        }
      }
      return lst;
    },
    numericOptions() {
      let lst = JSON.parse(JSON.stringify(this._numericOptions));
      if (this.value) {
        if (!lst.find(({ value }) => value == this.value)) {
          lst.push({ label: this.value, value: this.value });
        }
      }
      return lst;
    }
  },
  watch: {
    defaultInputType(n) {
      this.inputType = n;
    },
    inputType(n, o) {
      if (n == "text_list") {
        this.iValue = "text_list";
      } else {
        if (o == "text_list") {
          this.iValue = ""; // restore the previous
        }
      }
    },
    iValue(n) {
      if (n != this.value)
        this.$emit("input", n && typeof n == "object" ? n.label : n);
    },
    value: {
      handler(n, o) {
        if (o == "text_list" && !n) {
          this.iValue = undefined;
          this.inputType = this.defaultInputType;
          return;
        }
        if (n && this.iValue === undefined) {
          this.iValue = n;
          this.inputType =
            this.inputTypeList.length == 1
              ? this.inputTypeList[0]
              : this.inputTypeList.indexOf(this.value) >= 0
              ? this.value
              : this.defaultInputType;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    itemText(item) {
      return this.$utils.capitalizeFirstLetter(
        this.$tc(
          item.replace(/numeric_format/, "customized").replace(/_format/, ""),
          1
        )
      );
    },
    isDateTimeFormat(check) {
      return this._datetimeOptions.find(({ value }) => value == check) != null;
    },
    isNumericFormat(check) {
      return this._numericOptions.find(({ value }) => value == check) != null;
    },
    datetimeExample(option) {
      return this._now.format(option.label || option.value);
    },
    durationExample(option) {
      const value = 3670;
      const fmt = moment
        .duration(value, this.durationUnit)
        .format(option.label || option.value, { trim: false });
      return `${value} ${this.durationUnit}  =>  ${fmt}`;
    },
    numericExample(option) {
      return this.$utils.sprintf(option.label || option.value, 123.45);
    },
    onFocus(){
      this.$nextTick(() => {
        if (this.$refs.vsel){
          this.$refs.vsel.search =
            this.iValue && typeof this.iValue == "object"
              ? this.iValue.label
              : this.iValue;
        }
      });
    }
  },
  beforeCreate() {
    // _ non reactive
    this._now = moment();
    this._datetimeOptions = [
      {
        value: "DD/MM/YYYY HH:mm:ss",
        label: "DD/MM/YYYY HH:mm:ss"
      },
      {
        value: "DD/MM/YY HH:mm:ss",
        label: "DD/MM/YY HH:mm:ss"
      },
      {
        value: "DD/MM/YY HH:mm",
        label: "DD/MM/YY HH:mm"
      },
      {
        value: "MM/DD/YYYY HH:mm:ss",
        label: "MM/DD/YYYY HH:mm:ss"
      },
      {
        value: "MM/DD/YY HH:mm:ss",
        label: "MM/DD/YY HH:mm:ss"
      },
      {
        value: "MM/DD/YY HH:mm",
        label: "MM/DD/YY HH:mm"
      },
      {
        value: "YYYY/MM/DD HH:mm:ss",
        label: "YYYY/MM/DD HH:mm:ss"
      },
      {
        value: "YY/MM/DD HH:mm:ss",
        label: "YY/MM/DD HH:mm:ss"
      },
      {
        value: "YY/MM/DD HH:mm",
        label: "YY/MM/DD HH:mm"
      },
      {
        value: "L LTS",
        label: "L LTS"
      }
    ];
    this._durationOptions = [
      {
        value: "hh[h]:mm[m]:ss[s]",
        label: "hh[h]:mm[m]:ss[s]"
      },
      {
        value: "hh[h]:mm[m]",
        label: "hh[h]:mm[m]"
      },
      {
        value: "mm[m]:ss[s]",
        label: "mm[m]:ss[s]"
      },
      {
        value: "hh:mm:ss",
        label: "hh:mm:ss"
      },
      {
        value: "hh:mm",
        label: "hh:mm"
      },
      {
        value: "mm:ss",
        label: "mm:ss"
      },
      {
        value: "hh:mm:ss S",
        label: "hh:mm:ss S"
      }
    ];
    this._numericOptions = (
      this?.$root?.config?.references?.data_value_format_types || []
    )
      .filter(({ format_mask }) => format_mask != "text_list")
      .map(({ format_mask }) => {
        return {
          value: format_mask,
          label: format_mask
        };
      });
  }
};
</script>

<style scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.select-option {
  margin: 0 -12px;
}

.select-option > div.text-info {
  margin: -5px 5px 5px 5px;
}

.dataSource {
  padding: 4px 0;
}

.dataSource > label {
  margin-bottom: 0;
}
.dataSource > label > input[type="radio"] {
  width: 10px;
}

.dataSource > label > span {
  vertical-align: top;
  margin: 0px 10px 0 2px;
  padding-top: 1px;
}
.v-select {
  background-color: white;
}
</style>
