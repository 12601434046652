<template>
  <div
    class="icon"
    v-if="icon"
    v-on:click.prevent.stop="onClick"
    v-bind:title="title"
  >
    <i :class="icon"></i>
  </div>
</template>

<script>
import { alertInfoConst, trialDaysConst, trialDaysLeftNotificationConst, contractStateConst } from "@/assets/constants.js";

export default {
  name: "ContractAlertIcon",
  props: {
    visibility: {
      type: String,
      default: () => "profile",
      required: false
    },
    value: {
      type: Boolean,
      default: () => false,
      required: false
    }
  },
  computed: {
    contractStates() {
      return this.$root.config.contract_status_indicator || [];
    },
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    contractStateId() {
      return this.contract.alerts_info ? this.contract.alerts_info.number : -1;
    },
    contractTrialDays() {
      if (!this.isTest) return 0;
      let days = moment(new Date()).diff(this.contract.trial_started_at, "days");
      return days;
    },
    isTest() {
      return this?.contract?.contract_state?.id == contractStateConst.TEST;
    },
    alertInfo() {
      let entry = null;
      if (this.contractStateId != -1) {
        entry =
          this.contractStates.find((i) => parseInt(i.state) == this.contractStateId) ||
          null;
        entry = entry && this.isVisible(entry) ? entry : null;
      }
      return entry;
    },
    icon() {
      let result = "";
      // Se for tipos de alerta WARNING ou BLOCKED
      if (this.alertInfo && this.alertInfo.state) {
        if (parseInt(this.alertInfo.state) == alertInfoConst.WARNING) {
          if (this.contractTrialDays > trialDaysConst) {
            result = "fa fa-warning text-red";
          } else if (this.contractTrialDays >= (trialDaysConst - trialDaysLeftNotificationConst)) {
            result = "fa fa-warning text-yellow";
          } else {
            result = "fa fa-warning";
          }
        } else if (parseInt(this.alertInfo.state) == alertInfoConst.BLOCKED) {
          result = "fa fa-warning text-red";
        }
      }

      // Se não tiver alerta ainda, mas for um contrato em Teste com data de
      // expiração em breve
      if (!result && this.contractTrialDays > trialDaysConst) {
        result = "fa fa-warning text-red";
      } else if (!result && this.contractTrialDays >= (trialDaysConst - trialDaysLeftNotificationConst)) {
        result = "fa fa-warning text-yellow";
      }

      return result;
    },
    title() {
      // Se for tipos de alerta WARNING ou BLOCKED
      if (this.alertInfo) {
        if (this.alertInfo.title) {
          return this.$t(this.alertInfo.title);
        } else if (parseInt(this.alertInfo.state) == alertInfoConst.WARNING) {
          if (this.isRoot) {
            return this.$t("contract_state_alerts.running_out_of_resources");
          } else {
            return (
              this.$t("contract_state_alerts.contract_requires_attention") +
              " " +
              this.$t("contract_state_alerts.please_contact_admin")
            );
          }
        } else if (parseInt(this.alertInfo.state) == alertInfoConst.BLOCKED) {
          if (this.isRoot) {
            return this.$t("contract_state_alerts.balance_is_not_enough");
          } else {
            return this.$t("contract_state_alerts.please_contact_admin");
          }
        }
      }

      // Se não tiver alerta ainda, mas for um contrato em Teste com data de
      // expiração em breve
      if (this.isRoot && this.contractTrialDays >= (trialDaysConst - trialDaysLeftNotificationConst)) {
        return this.$t("contract_state_alerts.test_ending");
      }

      return this.$t("contract_state_alerts.please_contact_admin");
    },
    isRoot() {
      return (this.$store.getters["user/loggedUser"] || {}).is_root || false;
    },
    usesPaymentGateway() {
      return this.$root?.config?.references?.payment_gateway;
    }
  },
  watch: {
    alertInfo: {
      handler(n) {
        this.$emit("input", n ? true : false);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    isVisible(entry) {
      if ("visibility" in entry) {
        let key = this.isRoot ? "owner" : "member";
        if (
          key in entry.visibility &&
          this.visibility in entry.visibility[key]
        ) {
          return entry.visibility[key][this.visibility];
        }
      }
      return true;
    },
    onClick() {
      if (this.isRoot) {
        if (this.usesPaymentGateway) {
          this.$router.push("/dashboard/settings/billing");
        } else if (this.alertInfo?.url) {
          this.$router.push(this.alertInfo.url);
        }
      }
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
  div.icon > i {
    font-size: 16pt;
  }
</style>